import {
  formatDataValue,
  isAfterDate,
  isFutureDate,
  isPastDate,
} from './formatDate';

import { CREATE_EVENT_FIELDS, CREATE_EVENT_LABELS } from '../constants/trips';
import { DEFAULTDATE, DEFAULTTIME } from '../../constants/time';

export const eventValidator = (formData) => {
  let newErrors = {};

  if (!formData[CREATE_EVENT_FIELDS.EVENT_TITLE].trim()) {
    newErrors[CREATE_EVENT_FIELDS.EVENT_TITLE] = CREATE_EVENT_LABELS.ERROR_EMPTY;
  }

  if (!formData[CREATE_EVENT_FIELDS.LOGIN].trim()) {
    newErrors[CREATE_EVENT_FIELDS.LOGIN] = CREATE_EVENT_LABELS.ERROR_EMPTY;
  }

  if (formData[CREATE_EVENT_FIELDS.EVENT_START]) {
    if (
      !formatDataValue(formData[CREATE_EVENT_FIELDS.EVENT_START], DEFAULTDATE)
    ) {
      newErrors[CREATE_EVENT_FIELDS.EVENT_START] = CREATE_EVENT_LABELS.ERROR_DATE;
    } else if (!isFutureDate(formData[CREATE_EVENT_FIELDS.EVENT_START])) {
      newErrors[CREATE_EVENT_FIELDS.EVENT_START] = CREATE_EVENT_LABELS.ERROR_DATE_IN_PAST;
    } else if (
      formData[CREATE_EVENT_FIELDS.EVENT_END]
      && isAfterDate(formData[CREATE_EVENT_FIELDS.EVENT_START], formData[CREATE_EVENT_FIELDS.EVENT_END])
    ) {
      newErrors[CREATE_EVENT_FIELDS.EVENT_START] = CREATE_EVENT_LABELS.ERROR_INVALID_START_DATE_RANGE;
    }
  }

  if (formData[CREATE_EVENT_FIELDS.EVENT_END]) {
    if (
      !formatDataValue(formData[CREATE_EVENT_FIELDS.EVENT_END], DEFAULTDATE)
    ) {
      newErrors[CREATE_EVENT_FIELDS.EVENT_END] = CREATE_EVENT_LABELS.ERROR_DATE;
    } else if (!isFutureDate(formData[CREATE_EVENT_FIELDS.EVENT_END])) {
      newErrors[CREATE_EVENT_FIELDS.EVENT_END] = CREATE_EVENT_LABELS.ERROR_DATE_IN_PAST;
    } else if (formData[CREATE_EVENT_FIELDS.EVENT_END] && !formData[CREATE_EVENT_FIELDS.EVENT_START]) {
      newErrors[CREATE_EVENT_FIELDS.EVENT_START] = CREATE_EVENT_LABELS.ERROR_MISSING_START_DATE;
    }
  }

  if (!formData[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE]) {
    newErrors[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] = CREATE_EVENT_LABELS.ERROR_EMPTY;
  } else if (
    !formatDataValue(formData[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE], DEFAULTDATE)
  ) {
    newErrors[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] = CREATE_EVENT_LABELS.ERROR_DATE;
  } else if (!isPastDate(formData[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE])) {
    newErrors[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] = CREATE_EVENT_LABELS.ERROR_CREATION_DATE_IN_FUTURE;
  }

  if (!formData[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME]) {
    newErrors[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME] = CREATE_EVENT_LABELS.ERROR_EMPTY;
  } else if (!formatDataValue(formData[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME], DEFAULTTIME)) {
    newErrors[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME] = CREATE_EVENT_LABELS.ERROR_TIME;
  }

  return newErrors;
};
