import { ERRORSFORALL } from '../constants/tripValidator';
import { rootService } from '../../v2/core/business';

/**
 * Analytics value
 * @typedef {Object} AnalyticsValue
 * @property {string} Name - Название
 * @property {number} Id - ID значения аналитики
 */

/**
 * Analytics
 * @typedef {Object} Analytics
 * @property {string} Name - Название
 * @property {Array.<AnalyticsValue>} Values - Список возможных значений
 * @property {number} Id - ID аналитики
 */

/**
 * @param {Analytics} currentAnalytics - Текущая аналитика
 * @param {Array.<number>} currentValues - Текущие значения аналитик
 * @param {number} newValueId - ID нового значения
 */
const applyAnalytics = (newValueId, currentValues, currentAnalytics) => {
  const { updateSelectedAnalytic } = rootService.services.analytics.customAnalytics;
  updateSelectedAnalytic(currentAnalytics.Id, newValueId);

  return currentValues.includes(newValueId) ? currentValues : [...currentValues, newValueId];
};

/**
 * @param {Analytics} currentAnalytics - Список всех пользовательских аналитик
 * @param {Array.<number>} currentValues - Текущие значения аналитик
 */
const removeAnalytics = (
  currentValues = [], currentAnalytics,
) => {
  const { selectedAnalytics, removeSelectedAnalytic } = rootService.services.analytics.customAnalytics;
  const value = selectedAnalytics[currentAnalytics.Id];
  removeSelectedAnalytic(currentAnalytics.Id);

  return currentValues.filter((valueId) => valueId !== value);
};

/**
 * @param {Analytics} singleAnalytics - Пользовательская аналитика
 * @param {Array.<number>} tripAnalytics - Значения аналитик для всей поездки
 * @param {Array.<number>} serviceAnalytics - Значения аналитик для услуги
 */
const validateSingleAnalytics = (analyticsValues, singleAnalytics = {}) => {
  const { Required, Id } = singleAnalytics;
  const { selectedAnalytics } = rootService.services.analytics.customAnalytics;

  return { [Id]: Required && !selectedAnalytics[Id] ? ERRORSFORALL.NOANALYTICS : '' };
};

/**
 * @param {Array.<Analytics>} analytics - Список всех пользовательских аналитик
 * @param {Array.<number>} tripAnalytics - Значения аналитик для всей поездки
 * @param {Array.<number>} serviceAnalytics - Значения аналитик для услуги
 */
const validateAnalytics = (
  tripAnalytics = [], serviceAnalytics = [], analytics = [],
) => analytics.reduce((acc, singleAnalytics) => {
  const { ApplyToTrip } = singleAnalytics;
  const tripAnalyticsValueIds = ApplyToTrip ? tripAnalytics : serviceAnalytics;

  return {
    ...acc,
    ...validateSingleAnalytics(tripAnalyticsValueIds, singleAnalytics),
  };
}, {});

export {
  applyAnalytics, removeAnalytics, validateAnalytics, validateSingleAnalytics,
};
